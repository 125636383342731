import React from 'react';
import './Timeline1.css';
import regIcon from "../../images/registration-form.webp";
import submissionIcon from "../../images/submission.webp";
import workshop1Icon from "../../images/onlineWorkshopSeries1.webp";
import workshop2Icon from "../../images/onlineWorkshopSeries2.webp";
import trophyIcon from "../../images/trophy.webp";

const timelineData = [
  <div className="timeline left" key="1">
    <div className="card" style={{ borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(1.6px)", WebkitBackdropFilter: "blur(1.6px)", overflow: "hidden" }}>
      <div className="card-body p-4">
        <div className="timeline-content">
          <img src={regIcon} alt="Registration Icon" className="timeline-icon" />
          <h3 style={{ color: "white", fontWeight: "bold" }}>Registration</h3>
        
        </div>
        <p style={{ color: "#FFC100", fontWeight: "bold" }} id="preg">Deadline: 1st of July 2024</p>
        <p style={{ color: "white" }} className="mb-0">
        Upon the opening of registrations, individuals interested in participating can fill their team details through the provided registration form and access the sample proposal by clicking the “Proposal Template” button which will be displayed above.
        </p>
      </div>
    </div>
  </div>,
  <div className="timeline right" key="2">
    <div className="card" style={{ borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(1.6px)", WebkitBackdropFilter: "blur(1.6px)", overflow: "hidden" }}>
      <div className="card-body p-4">
        <div className="timeline-content">
          <img src={submissionIcon} alt="Submission Icon" className="timeline-icon" />
          <h3 style={{ color: "white", fontWeight: "bold" }}>Proposal Submissions Open</h3>
        </div>
        <p style={{ color: "#FFC100", fontWeight: "bold" }}>Deadline: 14th of July 2024</p>
        <p style={{ color: "white" }} className="mb-0">
        All registered applicants are required to prepare their proposals in accordance with the provided sample structure and submit them through this official website.
        </p>
      </div>
    </div>
  </div>,
  <div className="timeline left" key="3">
    <div className="card" style={{ borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(1.6px)", WebkitBackdropFilter: "blur(1.6px)", overflow: "hidden" }}>
      <div className="card-body p-4">
        <div className="timeline-content">
          <img src={workshop2Icon} alt="Online Workshop Icon" className="timeline-icon" />
          <h3 style={{ color: "white", fontWeight: "bold" }}>ideaX<h6>(Semi Finals of hackX-Initial Ideathon)</h6> </h3>
          
          
        </div>
        <p style={{ color: "#FFC100", fontWeight: "bold" }}>Date: 17th of August 2024</p>
        <p style={{ color: "#FFC100", fontWeight: "bold" }}>Venue: Department of Industrial Management, University of Kelaniya</p>
        <p style={{ color: "white" }} className="mb-0">
        Following the initial screening process, 30 teams showcasing the most innovative ideas will be selected. Each team will have 7 minutes to pitch their ideas, followed by a 3-minute Q&A session. The top 12 teams will then advance to compete at the Grand Finals of hackX 9.0.
        </p>
      </div>
    </div>
  </div>,
  <div className="timeline right" key="4">
    <div className="card" style={{ borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(1.6px)", WebkitBackdropFilter: "blur(1.6px)", overflow: "hidden" }}>
      <div className="card-body p-4">
        <div className="timeline-content">
          <img src={workshop1Icon} alt="Online Workshop Icon" className="timeline-icon" />
          <h3 style={{ color: "white", fontWeight: "bold" }}>designX</h3>
        </div>
        <p style={{ color: "#FFC100", fontWeight: "bold" }} id='px'>Date: 24th of August 2024 - 14th of September 2024</p>
        
        <p style={{ color: "white" }} className="mb-0">
          A workshop series of four skill-building sessions, tailored for finalists at the Grand Finals of hackX 9.0.
        </p>
      </div>
    </div>
  </div>,
  <div className="timeline left" key="5">
    <div className="card" style={{ borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)", boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", backdropFilter: "blur(1.6px)", WebkitBackdropFilter: "blur(1.6px)", overflow: "hidden" }}>
      <div className="card-body p-4">
        <div className="timeline-content">
          <img src={trophyIcon} alt="Finals Icon" className="timeline-icon" />
          <h3 style={{ color: "white", fontWeight: "bold" }}>hackX 9.0: Grand Finals</h3>
        </div>
        <p style={{ color: "#FFC100", fontWeight: "bold" }}>Date: 29th of September 2024</p>
        <p style={{ color: "white" }} className="mb-0">
          This event represents the ultimate stage where contestants will present their further refined innovative ideas. Fifteen teams will compete: twelve teams selected from ideaX and three teams from ideasprint.
        </p>
      </div>
    </div>
  </div>
];

const Timeline1 = ({ pageRef }) => {
  return (
    <section ref={pageRef}>
      <div className="container py-5">
        <div className="main-timeline">
          {timelineData}
        </div>
      </div>
    </section>
  );
};

export default Timeline1;
