import React from 'react';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image1 from '../images/vidushan.webp';
import Image2 from '../images/lithmi.webp';
import Image3 from '../images/chathura.webp';
import Image4 from '../images/hiruni.webp';
import Image5 from '../images/uchintha.webp';
import Image6 from '../images/ridmi.webp';
import Image7 from '../images/hirun.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles1.css'; // Assume this contains our custom styles

function DarkVariantExample() {
  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => alert(`Email ${email} copied to clipboard`))
      .catch((error) => console.error('Error copying text: ', error));
    window.location.href = `mailto:${email}`;
  };

  const clickableStyle = { cursor: 'pointer' };

  const cardsData = [
    {
      image: Image1,
      name: "Pramuditha Vidushan",
      role: "Chief Coordinator",
      phone: "(+94) 70 111 6121",
      email: "vidushan@viyrl.com"
    },
    {
      image: Image2,
      name: "Lithmi Kihansa",
      role: "Chief Coordinator",
      phone: "(+94) 70 676 1613",
      email: "liteenilanjith@gmail.com"
    },
    {
      image: Image3,
      name: "Chathura Kumarasinghe",
      role: "Financial Coordinator",
      phone: "(+94) 77 629 0618",
      email: "kumarasinghac221@gmail.com"
    },
    {
      image: Image4,
      name: "Hiruni Withanagamage",
      role: "Financial Coordinator",
      phone: "(+94) 71 562 0806",
      email: "hirunihansika625@gmail.com"
    },
    {
      image: Image5,
      name: "Uchintha Bandara",
      role: "Partnership Coordinator",
      phone: "(+94) 71 803 3621",
      email: "bandarauchintha505@gmail.com"
    },
    {
      image: Image6,
      name: "Ridmi Silva",
      role: "Partnership Coordinator",
      phone: "(+94) 76 260 9770",
      email: "liyanagesilva2001@gmail.com"
    },
    {
      image: Image7,
      name: "Hirun Senarathna",
      role: "Marketing Coordinator",
      phone: "(+94) 70 442 2486",
      email: "hirunsenarathna@gmail.com"
    },
  ];

  // Group cards into sets of 3 for desktop view
  const groupedCards = [];
  for (let i = 0; i < cardsData.length; i += 3) {
    groupedCards.push(cardsData.slice(i, i + 3));
  }

  return (
    <div className="team-cards-section" style={{ padding: '20px', color: '#fff' }}>
      {/* Desktop Carousel (3 cards per slide) */}
      <div className="d-none d-md-block">
        <Carousel interval={2000} indicators={groupedCards.length > 1}>
          {groupedCards.map((group, idx) => (
            <Carousel.Item key={idx}>
              <Container>
                <Row className="justify-content-center">
                  {group.map((card, index) => (
                    <Col key={index} md={4}>
                      <Card className="custom-card" style={{ width: '100%', color: '#fff', borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)",
                        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                        backdropFilter: "blur(1.6px)",
                        WebkitBackdropFilter: "blur(1.6px)",
                        overflow: "hidden", marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                          <img 
                            src={card.image} 
                            alt={card.name} 
                            style={{ 
                              maxWidth: '120px', 
                              maxHeight: 'auto', 
                              objectFit: 'cover', 
                              borderRadius: '25px', 
                              marginBottom: '-9px', 
                              marginTop:"-18px",
                              transition: 'transform 0.3s ease',
                            }} 
                          />
                        </div>
                        <Card.Body>
                          <Card.Title><center style={{marginBottom:"-20px",marinTop:"-15px"}}>{card.name}</center></Card.Title>
                          <Card.Text>
                            <h5 style={{ color: '#F1B61E', textAlign: 'center' ,marginTop:"30px"}}>{card.role}</h5>
                            <center style={{marginBottom:"-30px"}}>
                              <p onClick={() => handlePhoneClick(card.phone)} style={{clickableStyle}}>
                                <AiOutlinePhone style={{ marginRight: "10px" }} />
                                {card.phone}
                              </p>
                              <p onClick={() => handleEmailClick(card.email)} style={{clickableStyle,marginTop:"-10px"}}>
                                <AiOutlineMail style={{ marginRight: "10px" }} />
                                {card.email}
                              </p>
                            </center>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      
      {/* Mobile Carousel (1 card per slide) */}
      <div className="d-block d-md-none">
        <Carousel interval={2000} indicators={cardsData.length > 1}>
          {cardsData.map((card, idx) => (
            <Carousel.Item key={idx}>
              <Container>
                <Row className="justify-content-center row1">
                  <Col xs={12}>
                    <Card className="custom-card" style={{ width: '100%', color: '#fff', borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)",
                      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                      backdropFilter: "blur(1.6px)",
                      WebkitBackdropFilter: "blur(1.6px)",
                      overflow: "hidden", marginBottom: '20px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                        <img 
                          src={card.image} 
                          alt={card.name} 
                          style={{ 
                            maxWidth: '120px', 
                            maxHeight: 'auto', 
                            objectFit: 'cover', 
                            borderRadius: '25px', 
                            marginBottom: '-9px', 
                            marginTop:"-18px",
                            transition: 'transform 0.3s ease',
                          }} 
                        />
                      </div>
                      <Card.Body>
                        <Card.Title><center style={{marginBottom:"-20px",marinTop:"-15px"}}>{card.name}</center></Card.Title>
                        <Card.Text>
                          <h5 style={{ color: '#F1B61E', textAlign: 'center' ,marginTop:"30px"}}>{card.role}</h5>
                          <center style={{marginBottom:"-30px"}}>
                            <p onClick={() => handlePhoneClick(card.phone)} style={{clickableStyle}}>
                              <AiOutlinePhone style={{ marginRight: "10px" }} />
                              {card.phone}
                            </p>
                            <p onClick={() => handleEmailClick(card.email)} style={{clickableStyle,marginTop:"-10px"}}>
                              <AiOutlineMail style={{ marginRight: "10px" }} />
                              {card.email}
                            </p>
                          </center>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default DarkVariantExample;
