import React from 'react';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import "./ImageContainer.css";

const ImageContainer = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '-80px' }} className='containerX'>
      <motion.div
        animate={{ rotate: [0, 10, -10, 0], transition: { duration: 4, repeat: Infinity, repeatDelay: 2 } }} // Adjust duration and repeatDelay properties
        style={{ display: 'flex', justifyContent: 'center', originX: 0.5, originY: 1 }} // Set originX to 0.5 (center) and originY to 1 (bottom)
      >
        <img src="mascot.webp" alt="Mascot" style={{ maxWidth: '70%' }} />
      </motion.div>
    </div>
  );
}

export default ImageContainer;
