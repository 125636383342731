import React from "react";

import Header from "../components/Header"
import ImageContainer from "../components/ImageContainer/ImageContainer";

const WhatIsHackXPage = ({ pageRef }) => {
  const pageStyle = {
    marginTop: '20px',
    marginLeft: '10px',  // Adjust these values as needed
    marginRight: '10px', // Adjust these values as needed
    textAlign: 'center',
    paddingLeft: '10px', // Additional padding for extra spacing
    paddingRight: '10px' // Additional padding for extra spacing
  };

  return (
    <div ref={pageRef} style={pageStyle}>
      <Header />
      <ImageContainer/>
    </div>
  );
};

export default WhatIsHackXPage;